<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
      <div class="detail-index-crumbs">
        <span class="detail-index-crumbs-up" @click="goBack()" style="color:black;cursor:pointer">{{name}}</span> /
        <span class="detail-index-crumbs-this">{{ info.name }}</span>
      </div>
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img
            style="width: 100%; height: 100%"
            :src="info.detail_pic"
            alt=""
          />
        </div>
        <div class="detail-index-top-right">
          <div class="intangible-detail-top-title">{{ info.name }}</div>
          <div class="intangible-detail-top-des" :style="{ 'margin-bottom': info.detail_pic == '' ? '0rem' : '0.92rem' }">
            <ul class="intangible-detail-top-des-ul">
              <li class="intangible-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_date_culture.png"
                  alt=""
                />
                <div class="intangible-detail-top-des-ul-li-div">
                  <span>公布时间：</span>
                  <span>{{ info.released_date}}</span>
                </div>
              </li>
              <li class="intangible-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_cate_intangible.png"
                  alt=""
                />
                <div class="intangible-detail-top-des-ul-li-div">
                  <span>类别：</span>{{ info.cate_name ? info.cate_name : "" }}
                </div>
              </li>
              <li class="intangible-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_successor_intangible.png"
                  alt=""
                />
                <div class="intangible-detail-top-des-ul-li-div">
                  <span>传承人：</span
                  >{{ info.successor ? info.successor : "" }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">非遗详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <!-- <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  项目简介
                </div>
              </div> -->
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.des">{{ info.des }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cdetail from "../components/Cdetail";
import {
  queryResourceList,
  queryResourceLike,
  queryResourceShare,
  queryResourceUp,
} from "@/api/common";
import { queryResourceDetail } from "@/api/resource";
import axios from "axios";

export default {
  components: { Cdetail },
  data() {
    return {
      id: null,
      detailType: "intangible",
      info: {},
      intangible_team_list: [],
      likeCount: 0,
      shareCount: 0,
      upCount: 0,
      pageLoading: true,
      // 是否收藏
      isShouCang: false,
      // 是否点赞
      isLike: false,
      name:this.$route.query.name
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    window.scroll(0, 0);

    this.getResourceInfo();
  },
  updated() {
    if (window.localStorage.getItem("shoucang")) {
      const obj1 = JSON.parse(window.localStorage.getItem("shoucang"));
      // console.log(obj1);
      this.isShouCang = obj1.isShouCang;
      this.likeCount = obj1.likeCount;
    }
    if (window.localStorage.getItem("liking")) {
      const obj2 = JSON.parse(window.localStorage.getItem("liking"));
      // console.log(obj2);
      this.isLike = obj2.isLike;
      this.upCount = obj2.upCount;
    }
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal,oldVal);
      this.id = newVal.query.id;
      this.getResourceInfo();
    },
  },
  methods: {
    changeInfo(id) {
      //   this.$router.replace( { id: id } );
      this.$router.push(`detail?id=${id}`);
      this.$router.go();
      document.documentElement.scrollTop = 0;
      //   this.id = id;
    },
    getResourceInfo() {
      queryResourceDetail({ id: this.id }).then((res) => {
        console.log('--------------------------')
        console.log(res)
        this.pageLoading = false;
        const regex = new RegExp("<img", "gi");
        res.data.des = res.data.des.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        this.info = res.data;

        this.info.released_date = this.info.released_date == "" ? '无':this.info.released_date
        this.info.cate_name = this.info.cate_name == "" ? '无':this.info.cate_name
        this.info.successor = this.info.successor == "" ? '无':this.info.successor
        this.info.detail_pic = this.info.detail_pic == "" ? 'https://image.bookgo.com.cn/image/defaultM.jpg':this.info.detail_pic
        this.getRecommendList();
        this.getStats();
      });
    },
    getStats() {
      let self = this;
      let params = {
        aid: process.env.VUE_APP_AID,
        resource_type: this.info.resource_type,
        resource_id: this.id,
      };
      axios
        .all([
          queryResourceLike(params),
          queryResourceShare(params),
          queryResourceUp(params),
        ])
        .then(
          axios.spread(function(a, b, c) {
            self.likeCount = a.data;
            self.shareCount = b.data;
            self.upCount = c.data;
          })
        );
    },

    // 收藏
    shouCang() {
      this.isShouCang = !this.isShouCang;
      if (this.isShouCang) {
        this.likeCount += 1;
      } else {
        this.likeCount -= 1;
      }
      window.localStorage.setItem(
        "shoucang",
        JSON.stringify({
          isShouCang: this.isShouCang,
          likeCount: this.likeCount,
        })
      );
    },
    // 点赞
    Liking() {
      this.isLike = !this.isLike;
      if (this.isLike) {
        this.upCount += 1;
      } else {
        this.upCount -= 1;
      }
      window.localStorage.setItem(
        "liking",
        JSON.stringify({ isLike: this.isLike, upCount: this.upCount })
      );
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/resource`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/intangible_detail.scss";
</style>
